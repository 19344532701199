import {getShortDate, getTime, moment} from '@wix/events-moment-commons'
import {ScheduleDays, ScheduleItem} from '../types'
import {ALL_LOCATIONS} from '../reducers/filter'
import {ScheduleState} from '../reducers/schedule'

export const getDays = ({schedule, locale, isSSR, bookmarkedOnly = false}: GetDaysParams): ScheduleDays => {
  const items = bookmarkedOnly ? schedule.items.filter(item => item.bookmarked) : schedule.items

  return items.reduce(
    (
      acc,
      {timeSlot, status, formattedUpdatedDate, formattedDuration, name, id, description, tags, stageName, bookmarked},
    ) => {
      const {timeZoneId, start, end} = timeSlot
      const key = moment.tz(start, timeZoneId).format('YYYYMMDD')

      const scheduleItem: ScheduleItem = {
        status,
        formattedUpdatedDate,
        formattedDuration,
        formattedTimeSlot: isSSR ? '' : `${getTime(start, timeZoneId, locale)} - ${getTime(end, timeZoneId, locale)}`,
        name,
        id,
        description,
        tags: tags?.map(tag => ({
          checked: false,
          title: tag,
          value: tag,
        })),
        location: stageName,
        bookmarked,
      }
      acc[key] = {
        title: isSSR ? '' : getShortDate(start, timeZoneId, locale),
        items: [...(acc[key]?.items ?? []), scheduleItem],
      }

      return acc
    },
    {} as ScheduleDays,
  )
}

export interface GetDaysParams {
  schedule: ScheduleState
  locale: string
  isSSR: boolean
  bookmarkedOnly?: boolean
}

export interface ScheduleTag {
  checked: boolean
  title: string
  value: string
}

export const getTags = ({availableTags}: ScheduleState, selectedTags: string[]): ScheduleTag[] =>
  availableTags.map(value => ({
    value,
    title: value,
    checked: selectedTags.includes(value),
  }))

export interface ScheduleLocation {
  id: string
  value: string
  isDisabled: boolean
  isSelectable: boolean
}

export const getLocations = ({availableLocations}: ScheduleState, t: Function): ScheduleLocation[] => {
  const allLocations = {
    id: ALL_LOCATIONS,
    value: t('schedulePlaces.all'),
    isDisabled: false,
    isSelectable: true,
  }

  return [
    allLocations,
    ...availableLocations
      .filter(location => Boolean(location))
      .map(value => ({id: value, value, isDisabled: false, isSelectable: true})),
  ]
}
