import type {AnyAction} from 'redux'
import type {State} from '../../reducers'
import {viewPersonalAgenda} from '../../actions/schedule'

export const handleAgendaClick = (state: State, action: AnyAction) => {
  if (viewPersonalAgenda.pending.match(action)) {
    const {origin} = action.meta.arg
    return {
      evid: 217,
      origin,
      event_id: state.event.id,
      schedule_item_id: null as any,
      // schedule_item_id: null, TODO: send itemId when schedule item page is done
    }
  }
}
