import {WixEventsWeb} from '@wix/ambassador-wix-events-web/http'
import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {
  EVENTS_APP_ID,
  EVENTS_WEB_API_URL,
  EVENTS_WEB_API_BASE,
  GenericAPI,
  getAmbassadorHeaders,
  addQueryParams,
  draftEventPreviewUrlQueryParamName,
} from '@wix/wix-events-commons-statics'
import type {ControllerParams} from '@wix/yoshi-flow-editor'
import {Event, EventFieldset, EventStatus} from '@wix/ambassador-wix-events-web/types'
import {
  CreateBookmarkResponse,
  DeleteBookmarkResponse,
  ListBookmarksResponse,
} from '@wix/ambassador-wix-events-agenda/types'
import {ScheduleResponse} from '../types'

export const createApi = (controllerParams: ControllerParams) => {
  const {flowAPI} = controllerParams
  let authorization = flowAPI.controllerConfig.appParams.instance

  flowAPI.controllerConfig.wixCodeApi.site.onInstanceChanged(({instance}) => {
    authorization = instance
  }, EVENTS_APP_ID)

  const getHeaders = () => ({
    Authorization: authorization,
    'Content-Type': 'application/json',
  })

  const baseUrl = getBaseUrl(controllerParams)

  const api = new GenericAPI(`${baseUrl}${EVENTS_WEB_API_BASE}`, () => getHeaders())

  const wixEvents = WixEventsWeb(`${baseUrl}${EVENTS_WEB_API_URL}`)

  return {
    getEventBySlug: async (slug: string, draftPreviewToken?: string) =>
      (
        await api.get(
          addQueryParams(`/web/event-by-slug/v2/${slug}?personalAgenda=true`, {
            [draftEventPreviewUrlQueryParamName]: draftPreviewToken,
          }),
        )
      ).event,
    getEvent: async () =>
      (
        await wixEvents
          .EventManagement()(getAmbassadorHeaders(authorization))
          .list({
            limit: 1,
            offset: 0,
            fieldset: [EventFieldset.AGENDA],
            status: [EventStatus.SCHEDULED, EventStatus.STARTED],
          })
      ).events[0],
    getDemoEvent: (timeZoneId: string, language: string): Promise<Event> =>
      api.get(`/web/demo/event?tz=${timeZoneId}&lang=${language}`),
    getSchedule: (
      eventId: string,
      location: string,
      tags: string[],
      locale: string,
      draft: boolean = false,
    ): Promise<ScheduleResponse> =>
      api.get(
        addQueryParams(`/web/events/${eventId}/schedule-items`, {
          stageName: location ? [location] : undefined,
          tag: tags.length ? tags : undefined,
          locale,
          draft: draft ? 'true' : 'false',
        }),
      ),
    getDemoSchedule: (timeZoneId: string, language: string): Promise<ScheduleResponse> =>
      api.get(`/web/demo/schedule?tz=${timeZoneId}&lang=${language}`),
    getUserProfileImage: (userId: string) => api.get(`/web/user/${userId}/profileImage`),
    getBookmarks: (eventId: string): Promise<ListBookmarksResponse> => api.get(`/web/events/${eventId}/bookmarks`),
    createBookmark: (eventId: string, itemId: string): Promise<CreateBookmarkResponse> =>
      api.post(`/web/events/${eventId}/bookmarks/${itemId}`),
    deleteBookmark: (eventId: string, itemId: string): Promise<DeleteBookmarkResponse> =>
      api.delete(`/web/events/${eventId}/bookmarks/${itemId}`),
  }
}

export const getBaseUrl = (controllerParams: ControllerParams, fallback = '') => {
  if (controllerParams.flowAPI.environment.isSSR) {
    return parseBaseUrl(controllerParams.controllerConfig.wixCodeApi).root
  } else {
    return fallback
  }
}

export const parseBaseUrl = (wixCodeApi: IWixAPI) => {
  const baseUrl = wixCodeApi.location.baseUrl
  const baseUrlParts = baseUrl.split('/')
  return {
    root: baseUrlParts.slice(0, 3).join('/'),
    relative: baseUrlParts.slice(3).join('/'),
  }
}

export type Api = ReturnType<typeof createApi>
