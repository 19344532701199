import {createReducer} from '@reduxjs/toolkit'
import {Event} from '@wix/ambassador-wix-events-web/types'
import {getEvent, getDemoEvent} from '../actions/event'

const initialState: Event = {}

export const event = createReducer(initialState, builder => {
  builder
    .addCase(getEvent.fulfilled, (state, action) => action.payload)
    .addCase(getDemoEvent.fulfilled, (state, action) => action.payload)
})
