import {MEMBERS_INFO_APP_ID, MEMBERS_INFO_SECTION_ID} from '@wix/wix-events-commons-statics'
import {createAsyncAction} from '../services/redux-toolkit'

export const checkMembersAreaInstallation = createAsyncAction<boolean>(
  'CHECK_MEMBERS_AREA_INSTALLATION',
  (
    _,
    {
      extra: {
        controllerParams: {
          controllerConfig: {wixCodeApi},
        },
      },
    },
  ) =>
    wixCodeApi.site.isAppSectionInstalled({sectionId: MEMBERS_INFO_SECTION_ID, appDefinitionId: MEMBERS_INFO_APP_ID}),
)

export type CheckMembersAreaInstallation = typeof checkMembersAreaInstallation
