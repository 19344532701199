import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import {
  BiParams,
  createUouBiMiddlewareWithBiParams,
  getFormattedFullLocation,
  isPersonalAgendaEnabled,
} from '@wix/wix-events-commons-statics'
import {getFormattedFullDate} from '@wix/events-moment-commons'
import type {ControllerParams, CreateControllerFn} from '@wix/yoshi-flow-editor'
import {bindActionCreators} from 'redux'
import * as eventsUou from './bi/uou-bi-events-map'
import {getEvent, getDemoEvent} from './actions/event'
import {
  getSchedule,
  getDemoSchedule,
  readDescriptionClicked,
  createBookmark,
  deleteBookmark,
  getBookmarks,
  viewPersonalAgenda,
} from './actions/schedule'
import {createApi} from './api/api'
import {rootReducer, State} from './reducers'
import {getEventDetailsUrl} from './services/location'
import {getDays, getLocations, getTags} from './services/schedule'
import {EventScheduleProps} from './Widget/interfaces'
import {
  toggleScheduleItemModal,
  toggleFiltersModal,
  togglePersonalAgendaModal,
  toggleLoginDrawer,
  closeModal,
} from './actions/modals'
import {toggleTag, setLocation, resetFilter, setFilter} from './actions/filter'
import {schedulePageLoaded} from './actions/page-loaded'
import {decodeInstance} from './services/instance'
import {navigateToEventDetails} from './actions/navigate'
import {checkMembersAreaInstallation} from './actions/installed-apps'
import {promptLogin, logout, loadCurrentUser, addLoginListener} from './actions/member'
import {isUserLoggedIn} from './selectors/member'

const createController: CreateControllerFn = async controllerParams => {
  return {
    async pageReady() {
      const {isEditor, language} = controllerParams.flowAPI.environment
      const api = createApi(controllerParams)
      const {wixCodeApi, platformAPIs, appParams, compId} = controllerParams.controllerConfig
      const decodedInstance = decodeInstance(appParams.instance)
      const biMiddleware = createBiMiddleware({
        wixCodeApi,
        platformAPIs,
        appParams,
        compId,
        user: {
          aid: decodedInstance.aid,
          uid: decodedInstance.uid,
        },
      })

      const store = configureStore({
        reducer: rootReducer,
        middleware: [...biMiddleware, ...getDefaultMiddleware({thunk: {extraArgument: {api, controllerParams}}})],
      })

      store.dispatch(addLoginListener() as any)
      await store.dispatch(getEvent() as any)

      const personalAgendaEnabled = isPersonalAgendaEnabled(store.getState().event as wix.events.Event)

      if (personalAgendaEnabled) {
        await Promise.all([
          store.dispatch(checkMembersAreaInstallation() as any),
          store.dispatch(loadCurrentUser() as any),
        ])
      }

      if (store.getState().event?.id) {
        await store.dispatch(getSchedule() as any)

        if (personalAgendaEnabled && isUserLoggedIn(store.getState().member)) {
          await store.dispatch(getBookmarks() as any)
        }
      }

      const state = store.getState()
      const totalScheduleItems = state.schedule.total
      const showDemoSchedule = isEditor && (!state.event || totalScheduleItems === 0)

      if (showDemoSchedule) {
        const timeZoneId = state.event.scheduling?.config?.timeZoneId ?? getTimeZone(controllerParams)
        await store.dispatch(getDemoEvent({timeZoneId, language}) as any)
        await store.dispatch(getDemoSchedule({timeZoneId, language}) as any)
      }

      const actions = bindActionCreators(
        {
          toggleTag,
          setFilter,
          setLocation,
          toggleScheduleItemModal,
          toggleFiltersModal,
          togglePersonalAgendaModal,
          resetFilter,
          readDescriptionClicked,
          navigate: navigateToEventDetails,
          checkMembersAreaInstallation,
          promptLogin,
          logout,
          loadCurrentUser,
          getBookmarks,
          createBookmark,
          deleteBookmark,
          toggleLoginDrawer,
          viewPersonalAgenda,
          closeModal,
        },
        store.dispatch,
      )
      const {event} = store.getState()
      const eventDetailsUrl = await getEventDetailsUrl(controllerParams, event)

      const props: EventScheduleProps = {...getProps(store.getState(), controllerParams), ...actions, eventDetailsUrl}
      controllerParams.flowAPI.controllerConfig.setProps(props)

      if (wixCodeApi.window.viewMode === 'Site') {
        store.dispatch(schedulePageLoaded({totalScheduleItems}))
      }

      if (state.error) {
        controllerParams.flowAPI.reportError(state.error.message)
      }

      store.subscribe(async () => {
        const newState = store.getState()
        const error = newState.error
        const newProps = await getProps(newState, controllerParams)

        if (error) {
          controllerParams.flowAPI.reportError(error.message)
        }

        controllerParams.flowAPI.controllerConfig.setProps(newProps)
      })
    },
  }
}

const getProps = (state: State, controllerParams: ControllerParams): EventScheduleProps => {
  const {member, installedApps, filter, modals, event, schedule} = state
  const controllerConfig = controllerParams.controllerConfig
  const wixCodeApi = controllerConfig.wixCodeApi
  const {t} = controllerParams.flowAPI.translations
  const locale = wixCodeApi.site.regionalSettings
  const {location: selectedLocation, tags: selectedTags} = filter
  const locations = getLocations(schedule, t)
  const isSSR = controllerParams.flowAPI.environment.isSSR
  const bookmarksCount = schedule.bookmarks.length
  const personalAgendaEnabled = isPersonalAgendaEnabled(event as wix.events.Event)

  return {
    formattedEventDateAndLocation:
      isSSR || !event.id
        ? ''
        : `${getFormattedFullDate(event as wix.events.Event, locale, false)} ${getFormattedFullLocation(
            event as wix.events.Event,
          )}`,
    eventTitle: event.title,
    locations,
    tags: getTags(schedule, selectedTags),
    days: getDays({schedule, locale, isSSR}),
    bookmarkedDays: getDays({schedule, locale, isSSR, bookmarkedOnly: true}),
    selectedLocation,
    modals,
    filter,
    scheduleTotal: schedule.total,
    installedApps,
    member,
    bookmarksCount,
    personalAgendaEnabled,
  }
}

const getTimeZone = (controllerParams: ControllerParams) => {
  return typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function'
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : controllerParams.controllerConfig.wixCodeApi.site.timezone
}

const createBiMiddleware = (biParams: BiParams) => [createUouBiMiddlewareWithBiParams(biParams, eventsUou)]

export default createController
