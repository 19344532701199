import {combineReducers} from '@reduxjs/toolkit'
import {schedule} from './schedule'
import {event} from './event'
import {modals} from './modals'
import {filter} from './filter'
import {error} from './error'
import {installedApps} from './installed-apps'
import {member} from './member'

export const rootReducer = combineReducers({schedule, event, filter, modals, error, installedApps, member})

export type State = ReturnType<typeof rootReducer>
