import jwtDecode from 'jwt-decode'

export interface InstanceValues {
  metaSiteId: string
  aid: string
  uid: string
  instanceId: string
  siteIsTemplate: boolean
  appDefId: string
  biToken: string
  demoMode: boolean
  expirationDate: string
  hasUserRole: boolean
  permissions: 'string'
  signDate: string
  siteOwnerId: string
  visitorId: string
  msid: string
}

export const decodeInstance = (instance = ''): Partial<InstanceValues> => {
  try {
    const decodedInstance = jwtDecode(instance) as InstanceValues
    return {
      ...decodedInstance,
      visitorId: decodedInstance.aid || decodedInstance.uid || null,
      msid: decodedInstance.metaSiteId || null,
      instanceId: decodedInstance.metaSiteId || null,
      siteIsTemplate: Boolean(decodedInstance.siteIsTemplate) || false,
      demoMode: Boolean(decodedInstance.demoMode) || false,
      siteOwnerId: decodedInstance.siteOwnerId || null,
    }
  } catch (e) {
    return {
      visitorId: null,
      msid: null,
      instanceId: null,
      siteIsTemplate: false,
      demoMode: false,
      siteOwnerId: null,
    }
  }
}
