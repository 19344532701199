import {createReducer, SerializedError} from '@reduxjs/toolkit'
import {getSchedule, getDemoSchedule} from '../actions/schedule'
import {getDemoEvent, getEvent} from '../actions/event'
import {setLocation, resetFilter, toggleTag, setFilter} from '../actions/filter'

const initialState: SerializedError = null

export const error = createReducer(initialState, builder => {
  builder
    .addCase(getSchedule.rejected, (state, action) => action.error)
    .addCase(getDemoSchedule.rejected, (state, action) => action.error)
    .addCase(getDemoEvent.rejected, (state, action) => action.error)
    .addCase(getEvent.rejected, (state, action) => action.error)
    .addCase(setLocation.rejected, (state, action) => action.error)
    .addCase(resetFilter.rejected, (state, action) => action.error)
    .addCase(toggleTag.rejected, (state, action) => action.error)
    .addCase(setFilter.rejected, (state, action) => action.error)
})
